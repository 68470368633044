import { Pipe, PipeTransform } from "@angular/core";
import {
  BrandCodeEnum,
  PackageResponse,
  VehicleSearchResponse
} from "src/domain/client";

@Pipe({
  name: "originalServiceLink",
  standalone: true
})
export class OriginalServiceLinkPipe implements PipeTransform {
  transform(carInfo: VehicleSearchResponse, pkg: PackageResponse): string {
    switch (carInfo.brandCode) {
      case BrandCodeEnum.V:
        if (pkg.name.includes("4+"))
          return "https://www.volkswagen.se/sv/aga-en-vw/service/originalservice-4plus.html";
        if (pkg.name.includes("8+"))
          return "https://www.volkswagen.se/sv/aga-en-vw/service/originalservice-8plus.html";
        return "https://www.volkswagen.se/sv/aga-en-vw/service/originalservice.html";
      case BrandCodeEnum.A:
        if (pkg.name.includes("4+"))
          return "https://www.audi.se/sv/kopa-aga/service-tillbehor/originalservice/#Originalservice-4";
        if (pkg.name.includes("8+"))
          return "https://www.audi.se/sv/kopa-aga/service-tillbehor/originalservice/#Originalservice-8";
        return "https://www.audi.se/sv/kopa-aga/service-tillbehor/originalservice/";
      case BrandCodeEnum.N:
        if (pkg.name.includes("4+"))
          return "https://www.volkswagen-transportbilar.se/sv/aga-bil/service-och-underhall/originalservice-4plus.html";
        if (pkg.name.includes("8+"))
          return "https://www.volkswagen-transportbilar.se/sv/aga-bil/service-och-underhall/originalservice-8plus.html";
        return "https://www.volkswagen-transportbilar.se/sv/aga-bil/service-och-underhall/originalservice.html";
      case BrandCodeEnum.C:
        if (pkg.name.includes("4+"))
          return "https://www.skoda.se/aga-bil/skoda-service-originalservice-4";
        if (pkg.name.includes("8+"))
          return "https://www.skoda.se/aga-bil/skoda-service-originalservice-8";
        return "https://www.skoda.se/aga-bil/originalservice";
      case BrandCodeEnum.S:
        if (pkg.name.includes("4+"))
          return "https://www.seat.se/aga/service/seat-originalservice/seat-originalservice-fyra-plus";
        if (pkg.name.includes("8+"))
          return "https://www.seat.se/aga/service/seat-originalservice/seat-originalservice-atta-plus";
        if (carInfo.specialBrandCode)
          return "https://www.cupraofficial.se/aga/cupra-originalservice";
        return "https://www.seat.se/aga/service/seat-originalservice/seat-originalservice";
    }
  }
}
