import { environment } from "src/environments/environment";

export default class ServiceLinkClientBase {
  public getBaseUrl(data: string, baseUrl: string): string {
    return environment.serviceLinkApiUrl;
    // return (
    //   location.protocol + "//" + location.host + environment.serviceLinkApiUrl
    // );
  }
}
