export const environment = {
  production: true,
  mapsKey: "AIzaSyASTXa8ZyGjkbH2YwhXSJ3QcbW6MUNVvHk",
  apiUrl: "/Api",
  gtmKey: "GTM-NGLK422",
  hotjarId: "2375581",
  soultechScriptUrl: "https://scripts.webapp.prod.soultech.cloud/custom_vw.js",
  serviceLinkApiUrl: "https://app-vgsprod-sl-d-01.azurewebsites.net",
  features: ["service-link"]
};
