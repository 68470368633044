import { Pipe, PipeTransform } from "@angular/core";
import { BrandCodeEnum } from "src/domain/client";

@Pipe({
  name: "roadAssistantLink",
  standalone: true
})
export class RoadAssistantLinkPipe implements PipeTransform {
  transform(brand: BrandCodeEnum): string {
    switch (brand) {
      case BrandCodeEnum.A:
        return "https://www.audi.se/sv/kopa-aga/service-tillbehor/garanti/#V%C3%A4gassistans";
      case BrandCodeEnum.C:
        return "https://www.skoda.se/aga-bil/vagassistans";
      case BrandCodeEnum.S:
        return "https://www.seat.se/aga/service/seat-mobility/vagassistans-och-raddningsservice";
      case BrandCodeEnum.N:
        return "https://www.volkswagen-transportbilar.se/sv/aga-bil/service-och-underhall/basservice.html#vagassistansforsakring";
      case BrandCodeEnum.V:
        return "https://www.volkswagen.se/sv/aga-en-vw/service/basservice.html";
    }
    return "";
  }
}
